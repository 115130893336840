.loading-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
  color: #fff;
  font-size: 3rem;
}

.loading-animation::before {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.film-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.film-container iframe {
  width: 100%;
  height: 100%;
}

.skip-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #fff;
  color: #000;
  font-size: 1.5rem;
  padding: 10px 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
}
